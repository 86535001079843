import { Badge, Tooltip } from "antd";
import SelectorIcon from "./SelectorIcon";
import { Tooltip as ReactTooltip } from "react-tooltip";

function AllChatSelectorIcon({
  activated,
  Icon,
  tooltipTitle,
  handleClick,
  selected,
  chatCount,
}: any) {
  const handleChatIcon = () => {
    if (handleClick && activated) {
      handleClick();
    }
  };
  return (
    <div className="z-20" data-tooltip-id={tooltipTitle.replace(" ", "-")}>
      {/* <Tooltip title={"test"}> */}
      {chatCount ? (
        <Badge count={chatCount} overflowCount={10} size="default">
          <SelectorIcon
            Icon={Icon}
            activated={activated}
            selected={selected}
            handleChatIcon={handleChatIcon}
          />
        </Badge>
      ) : (
        <SelectorIcon
          Icon={Icon}
          activated={activated}
          selected={selected}
          handleChatIcon={handleChatIcon}
        />
      )}
      {/* </Tooltip> */}

      <div className="text-sm">
        <ReactTooltip
          id={tooltipTitle.replace(" ", "-")}
          place="top"
          content={tooltipTitle}
        />
      </div>
    </div>
  );
}

export default AllChatSelectorIcon;
