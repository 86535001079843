import { useContext, useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { HiOutlineBan } from "react-icons/hi";
import { formatDateTime } from "utils/timeFunctions";
import { ChatContext } from "context/ChatContext";
import { IUnreadChat } from "hooks/useUserChatWebSocket";
import { useSelector } from "react-redux";
import AllChatAvatar from "./AllChatAvatar";

function ChatUser({ chatData, setSelectedChat, popUp, setChatIsOpen }: any) {
  const loggedInUser = useSelector((state: any) => state?.user?.currentUser);
  const { unreadChats, setUnreadChats } = useContext(ChatContext);
  const [recieverName, setRecieverName] = useState("Unknown User");
  const [userData, setUserData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const handleClick = () => {
    setSelectedChat(chatData);

    setUnreadChats((prevChats: IUnreadChat[]) => {
      return prevChats.filter((chat: IUnreadChat) => {
        return chat.chatId != chatData.id;
      });
    });

    if (setChatIsOpen) {
      setChatIsOpen(true);
    }
  };

  useEffect(() => {
    const chat: IUnreadChat = unreadChats.find(
      (chat: IUnreadChat) =>
        chat.chatId == chatData?.id &&
        chatData.status !== "rejected" &&
        chatData.block_status !== "blocked"
    );

    setTimeout(() => {
      setUnreadCount(chat ? chat.count : 0);
    }, 200);
  }, [chatData?.id, unreadChats]);

  useEffect(() => {
    if (chatData.receiver) {
      setUserData(chatData.receiver);
      setRecieverName(getStringAccordingToLength(chatData.receiver?.name, 20));
    }
    // else {
    //   setIsLoading(true);
    //   const userId = chatData?.chat_name?.split("-")[1];
    //   // console.log("userId : ", userId);
    //   UserProfileService.getUserById(userId).then((res) => {
    //     // console.log("res : ", res?.profile);
    //     setUserData(res?.profile);
    //     setRecieverName(getStringAccordingToLength(res?.profile?.name, 20));
    //     setIsLoading(false);
    //   });
    // }
  }, [chatData]);

  const getStringAccordingToLength = (str: string, length: number) => {
    if (str?.length > length) {
      return `${str?.slice(0, length)}...`;
    } else {
      return str;
    }
  };

  return (
    <div
      className={`flex items-center gap-3 border-b-[1px] border-solid border-[var(--chat-border)] py-3 px-2 cursor-pointer hover:sm:bg-[var(--grey)] hover:rounded-lg`}
      onClick={popUp !== true ? handleClick : undefined}
    >
      <div className={popUp === true ? "w-12" : "w-14"}>
        <AllChatAvatar chatData={chatData} popUp={popUp} />
      </div>
      <div className="w-full">
        <div className="flex items-center justify-between w-full mb-1">
          <p
            className="flex items-center text-base text-white"
            data-tooltip-id={`chat-username`}
          >
            {!isLoading && recieverName}{" "}
            {chatData?.chat_name.split("-")[0] != loggedInUser?.id &&
            chatData?.status === "rejected" ? (
              <HiOutlineBan className="ml-2 text-red-700" />
            ) : (
              <></>
            )}
          </p>
          {chatData?.receiver && chatData.receiver?.name?.length > 20 && (
            <div className="text-sm">
              <ReactTooltip
                id={`chat-username`}
                place="top"
                content={chatData.name}
              />
            </div>
          )}
          {popUp !== true && (
            <div className="flex flex-col items-center ">
              <p className="text-xs text-[var(--clr-grey-special)] text-right">
                {formatDateTime(chatData?.latest_message?.updated_at)}
              </p>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <p className="text-sm text-[var(--Color7B9DB4)]">
            {!isLoading && (
              <>
                {chatData?.latest_message
                  ? getStringAccordingToLength(
                      chatData?.latest_message?.content,
                      20
                    )
                  : `No messages`}
              </>
            )}
          </p>
          {/* {popUp !== true && <Badge count={unreadCount} />} */}
        </div>
      </div>
    </div>
  );
}

export default ChatUser;
