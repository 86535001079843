import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { adjustVolume } from "redux/AudioPlayerRedux";
import { adjustVolumeVideo } from "redux/VideoPlayerRedux";
import videojs from "video.js";

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  width: 1920,
  height: 1080,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

// const iframeURL = process.env.REACT_APP_LIVETVPLAYER_IFRAME_URL;
const iframeURL = process.env.REACT_APP_VIDEOPLAYER_IFRAME_URL;
const VideoPlayerInsideLiveTV = () => {
  const dispatch = useDispatch<any>();
  const videoNode = React.useRef<HTMLVideoElement | null>(null);
  const player = React.useRef<videojs.Player>();
  const { slug } = useParams();
  const { currentLiveTv } = useSelector((state: any) => state?.video);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [innerHeight, setInnerHeight] = React.useState<number>(
    window.innerWidth / 2
  );
  const [adTrigger, setAdTrigger] = React.useState<boolean>(false);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      adjustHeight();
    });
  });
  const volume = useSelector((state: any) => state?.video?.volume);

  console.log("currentLiveTv stream_from->", currentLiveTv?.stream_from);

  React.useEffect(() => {
    const adTriggerTime = Date.now();
    const lastAd = sessionStorage.getItem("liveTV-AdTime");
    if (currentLiveTv?.override_vast_restriction === 1) {
      setAdTrigger(true);
      sessionStorage.setItem("liveTV-AdTime", adTriggerTime.toString());
    } else {
      if (lastAd) {
        // console.log(Date.now() - parseInt(lastAd, 10));
        if (Date.now() - parseInt(lastAd, 10) > 10 * 60 * 1000) {
          setAdTrigger(true);
          sessionStorage.setItem("liveTV-AdTime", adTriggerTime.toString());
        }
      } else {
        sessionStorage.setItem("liveTV-AdTime", adTriggerTime.toString());
        setAdTrigger(true);
      }
    }

    adjustHeight();
  }, []);
  // console.log('adTrigger : ' + adTrigger);
  const adjustHeight = () => {
    const aspectRatio = 16 / 9;

    if (window.innerWidth > 1500) {
      setInnerHeight((window.innerWidth * 0.62) / aspectRatio);
    } else if (window.innerWidth > 1300) {
      setInnerHeight((window.innerWidth * 0.62) / aspectRatio);
    } else if (window.innerWidth > 1100) {
      setInnerHeight((window.innerWidth * 0.62) / aspectRatio);
    } else if (window.innerWidth > 940) {
      setInnerHeight((window.innerWidth * 0.6) / aspectRatio);
    } else if (window.innerWidth > 640) {
      setInnerHeight((window.innerWidth * 0.6) / aspectRatio);
    } else if (window.innerWidth > 480) {
      setInnerHeight(window.innerWidth / aspectRatio);
    } else {
      setInnerHeight(window.innerWidth / aspectRatio);
    }
  };

  console.log(window.innerWidth + ' - ' + innerHeight);
  React.useEffect(() => {
    const videoJsOptions = {
      sources: [
        {
          controls: true,
          responsive: true,
          fluid: true,
          src: currentLiveTv?.stream_url,
          currentSrc: currentLiveTv?.stream_url,
          buffered: [0.0, 0.04],

          // type: 'video/mp4',
        },
      ],
    };
    player.current?.dispose();
    if (videoNode.current) {
      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...videoJsOptions,
      }).ready(function () {
        videoNode.current?.play();
      });
    }
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, [currentLiveTv]);

  useEffect(() => {
    const handleVolume = (event: any) => {
      if (
        event.source === iframeRef?.current?.contentWindow &&
        event.data.type === "volumeChange"
      ) {
        dispatch(adjustVolume(event.data.updatedVolume));
        dispatch(adjustVolumeVideo(event.data.updatedVolume));
      }
    };
    window.addEventListener("message", handleVolume);
    return () => {
      window.removeEventListener("message", handleVolume);
    };
  }, []);

  return (
    // <div className="w-[80%] mx-auto max-sm:w-full lg:overflow-hidden lg:h-max rounded-md">
    <div className="flex justify-center w-[80%] max-lg:w-[75%] max-xl:w-[80%] overflow-hidden  max-sm:w-full lg:overflow-hidden max-2xl:w-[82%]">
      {/* <video
        ref={videoNode}
        poster={currentLiveTv?.poster}
        id="my-player"
        className="video-js vjs-luxmty vjs-16-9"
      /> */}

      <iframe
        title={`AYOZAT LiveTV Player`}
        ref={iframeRef}
        width="100%"
        height={`${innerHeight}px`}
        // src={"http://127.0.0.1:5500/iframe/LiveTVPlayer.html"}
        src={
          currentLiveTv?.stream_from === "embed"
            ? currentLiveTv?.stream_url
            : iframeURL
        }
        onLoad={() => {
          const data = {
            source: "my-video",
            src: currentLiveTv?.stream_url,
            poster: currentLiveTv?.poster,
            vastEnabled: adTrigger,
            vastTagPreroll: adTrigger ? currentLiveTv?.vast_tag_url : "",
            pre_ad: adTrigger ? currentLiveTv?.pre_ad : "",
            vastDuration: 99999999999999999999999999999999999999999999,
            volume: volume,
          };
          const iframeWindow = iframeRef.current?.contentWindow;
          iframeWindow?.postMessage(data, "*");
        }}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen"
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default VideoPlayerInsideLiveTV;
