import { RouteName } from "constant/routeNames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CreatorQueueListSkeleton from "components/skeleton/CreatorQueueListSkeleton";
import LazyLoadImg from "components/LazyLoadImg";

function PlaylistQueueCard({
  setEpisodeDetails,
  video,
  season,
  setVideoDetails,
  id,
  slug,
}: any) {
  const navigate = useNavigate();
  const { currentVideo } = useSelector((state: any) => state?.video);

  // console.log(slug);

  const episodeOnClick = () => {
    navigate(
      RouteName.CreatorPlaylistRoute.replace(":slug", slug).replace(
        ":stream",
        video?.stream_key
      )
    );
    setEpisodeDetails(video);
    setVideoDetails(video);
  };

  return (
    <>
      <div
        className={`w-full flex  justify-between bg-[#1b1b1b] cursor-pointer px-3 py-3 rounded-md hover:bg-slate-800 hover:shadow-2xl  shadow-2xl max-sm:my-0 max-sm:w-full ${
          currentVideo?.id === id ? "bg-slate-800" : " "
        }`}
        onClick={episodeOnClick}
      >
        <div className="relative w-[240px] mr-5 rounded-lg h-[80px] bg-img white-text max-sm:h-[80px] max-sm:w-[80px] overflow-hidden ">
          {/* <img
              src={video?.thumbnail_url}
              alt="playlist_image"
              className="object-cover w-[100%] h-full overflow-hidden rounded-md"
            /> */}
          <LazyLoadImg
            imgSrc={video?.thumbnail_url}
            imgAlt={"Artist"}
            imgHeight={"100%"}
            imgWidth={"100%"}
            className="object-cover h-full overflow-hidden rounded-lg "
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center center",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <div className="absolute inset-x-0 bottom-0 flex justify-end p-2">
            <div className="h-full">
              <p className="text-[10px] text-white ml-2 font-[500]">
                {video?.runtime} min
              </p>
            </div>
          </div>
        </div>

        <div className="w-[100%]">
          <p className="text-xs text-white">
            {video?.title.length > 200 ? (
              <>{video?.title.slice(0, 200)}...</>
            ) : (
              <>{video?.title}</>
            )}
          </p>
        </div>
      </div>
    </>
  );
}

export default PlaylistQueueCard;
