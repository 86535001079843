import { RouteName } from "constant/routeNames";
import { BsFillPlayFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
function HeroCard({ data }: any) {
  const navigate = useNavigate();
  // console.log(data);
  const setDataToPlayer = async () => {
    if (data?.firstEpisode) {
      navigate(
        RouteName.WatchTVSeries.replace(":slug", data?.slug).replace(
          ":stream",
          data?.firstEpisode.stream_key
        )
      );
    } else {
      navigate(RouteName.WatchMovies.replace(":slug", data?.slug));
    }
  };
  return (
    <div>
      <div className="relative flex h-[450px] w-full max-sm:h-[500px]">
        <div
          className="absolute inset-0 bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url(${data.thumbnail_url})`,
            filter: "blur(8px)",
            zIndex: -1,
          }}
        ></div>

        <div className="flex h-[400px] w-full justify-between items-center videohero-back ">
          <div className="flex-grow w-[50%]">
            <div className="pt-20 pb-4 ml-4 max-sm:ml-6 cursor-default">
              <p className="text-2xl md:text-3xl lg:text-4xl font-semibold white-text max-sm:mt-8 w-[80%]">
                {/* Lady Gaga <br /> The Art of Music */}
                {data.title}
              </p>
              <div className="flex items-center">
                {data?.genres?.map((d: any, i: number) => (
                  <p
                    className="px-1 mt-4 mr-1 text-sm text-center rounded-sm bg-gray-600/50 gray-text"
                    key={i}
                  >
                    {d.name}
                  </p>
                ))}
              </div>

              <div className="relative overflow-y-scroll scrollbar-hide hide-scrollbar">
                <div
                  className="relative z-20 w-full"
                  style={{
                    backgroundImage:
                      "linear-gradient(to top, rgba(255, 255, 255, 0), white)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                ></div>
                <p className="mt-4 w-[50%] h-[100px]  leading-tight white-text max-sm:w-[90%] max-md:w-[90%] relative z-10"
                dangerouslySetInnerHTML={{ __html: data?.description }}>
                  {/* {data.description
                    .replace(/&amp;/g, "&")
                    .replace(/&rsquo;/g, "’")
                    .replace(/&nbsp;/g, "")
                    .replace(/&ldquo;/g, '"')
                    .replace(/&rdquo;/g, '"')
                    .replace(/&copy;/g, '©')
                    .replace(/&lsquo;/g, '‘')
                    .replace(/&rsquo;/g, '’')
                    } */}
                     
                </p>
              </div>

              <div className="flex items-center max-sm:block">
                <div className="flex items-center mt-20 text-sm xl:text-lg lg:text-lg md:text-md">
                  <div className="flex justify-center">
                    {/* <button className="flex items-center px-6 py-1 font-semibold text-black bg-white rounded-full max-sm:scale-125 max-sm:ml-3 hover:bg-red-600 hover:text-white">
                        <FaPlay className="mr-1 text-xs" /> Play
                      </button> */}
                    <button
                      className="w-[162px] h-[42px] bg-white  rounded-3xl hover:scale-105 hover:shadow-xl max-sm:w-[145px] flex items-center justify-center duration-200 ease-in-out"
                      onClick={setDataToPlayer}
                    >
                      <BsFillPlayFill className="stream-icon-hero" />
                      <span className="ml-2 text-black">Watch</span>
                    </button>

                    {/* <button className="px-6 py-1 mx-3 font-semibold text-white border-2 border-white rounded-full max-sm:scale-125 max-sm:ml-10">
                    Add to watchlist
                  </button> */}
                  </div>
                </div>
                {/* <div className="flex items-center mt-5 text-sm xl:text-lg lg:text-lg md:text-md">
                <div className="flex -space-x-2 overflow-visible">
                  <img
                    className="z-30 inline-block w-8 h-8 rounded-full md:h-10 md:w-10 ring-2 ring-red-700"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    className="z-20 inline-block w-8 h-8 rounded-full md:h-10 md:w-10 ring-2 ring-red-700"
                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    className="inline-block w-8 h-8 rounded-full md:h-10 md:w-10 ring-2 ring-red-700"
                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <p className="ml-2 text-xs md:text-sm gray-text">
                  David and 5 others watching
                </p>
              </div> */}
              </div>
            </div>
          </div>
          <div
            className="w-[265px] h-[400px] overflow-hidden bg-no-repeat bg-cover mr-[120px] max-sm:hidden rounded-lg"
            style={{ backgroundImage: `url(${data.thumbnail_url})` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default HeroCard;
